// TEMP Variable declarations
$list-style: none;
$base-font-size: 16px;

:root {
  --reach-dialog: 1;
  --reach-tabs: 1;

  @if global-variable-exists(scale) {
    --scale-element: 1;
    --scale-font: 1;

    // Check the $scale in tokens/layouts.scss
    @each $size in $scale {
      @media (max-height: nth($size, 1)) {
        --scale-element: #{nth($size, 2)};
        --scale-font: #{nth($size, 2)};
      }
    }
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  // Enable for debugging purpose
  // outline: 1px solid red;
}

/* Document */
html {
  // Prevent adjustments of font size after orientation changes in iOS.
  -webkit-text-size-adjust: 100%;
  // Remove gray overlay on links for iOS.
  -webkit-tap-highlight-color: transparent;
  font-size: $base-font-size;
}

body {
  position: relative;
  color: $dark-hex-80;
  font-family: $font-primary;
  background-color: $light-hex-100;
  // background-color: $bg-gray;
}

// Render the `main` element consistently in IE.
main {
  display: block;
}

pre {
  font-family: $font-monospace, monospace;
  font-size: inherit;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/* Embedded content
   ========================================================================== */
/**
* Remove the border on images inside links in IE 10.
*/
img {
  width: 100%;
  height: auto;
  border-style: none;
  vertical-align: bottom;
}
embed,
object,
iframe {
  border: 0;
  vertical-align: bottom;
}

code,
kbd,
samp {
  font-family: $font-monospace, monospace;
  padding: 2px 4px;
  margin: 0;
  font-size: 85%;
  background-color: rgba(27, 31, 35, 0.08);
  border-radius: 3px;
}

// Remove the gray background on active links in IE 10.
a {
  background-color: transparent;
  text-decoration: none;
  color: $brand-primary-200;
}

ul,
ol {
  list-style: $list-style;
}

ol ol,
ul ol {
  list-style-type: lower-roman;
}

ul ul ol,
ul ol ol,
ol ul ol,
ol ol ol {
  list-style-type: lower-alpha;
}

b,
strong {
  font-weight: $semibold;
}

// Horizontal lines
hr {
  // Add the correct box sizing in Firefox.
  box-sizing: content-box;
  height: 0;

  overflow: hidden;
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $dark-alpha-10;
  clear: both;
}

// Remove most spacing between table cells.
table {
  border-spacing: 0;
  border-collapse: collapse;
}

details {
  summary {
    cursor: pointer;
  }
  &:not([open]) {
    // Set details content hidden by default for browsers that don't do this
    > *:not(summary) {
      display: none !important;
    }
  }
}

// Disable Firefox dotted-lines on select input after selecting an option.
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
// option:not(:checked) {
//   color: black; /* prevent <option>s from becoming transparent as well */
// }

/**
 * Fix placeholder font properties inheritance.
 */
::-webkit-input-placeholder {
  font: inherit;
}
:-ms-input-placeholder {
  font: inherit;
}
::-ms-input-placeholder {
  font: inherit;
}
::placeholder {
  font: inherit;
}
/**
* Clickable labels
*/
label[for] {
  cursor: pointer;
}

// Corrent font-size accross browsers
small {
  font-size: 80%;
}

// Prevent `sub` and `sup` elements from affecting the line height in all browsers.
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}

address {
  font-style: inherit;
}

/* Forms
========================================================================== */
button,
input,
optgroup,
select,
textarea {
  font-family: $font-primary;
  vertical-align: middle;
  background: transparent;
  border: 0;
  outline: 0;
}

select {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  appearance: none;
}

button[disabled],
[type="button"][disabled],
[type="reset"][disabled],
[type="submit"][disabled] {
  cursor: default;
}

/**
* Remove the inner border and padding in Firefox.
*/
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
* Restore the focus styles unset by the previous rule.
*/
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
* Reset to invisible
*/
fieldset {
  border: 0;
  min-width: 0;
}

/**
* 1. Correct the text wrapping in Edge and IE.
* 2. Correct the color inheritance from `fieldset` elements in IE.
* 3. Remove the padding so developers are not caught out when they zero out
*    `fieldset` elements in all browsers.
*/
legend {
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
}

/**
* Correct the cursor style of increment and decrement buttons in Chrome.
*/
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
* 1. Correct the odd appearance in Chrome and Safari.
* 2. Correct the outline style in Safari.
*/
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/* MISC
   ========================================================================== */

// Add the correct display in IE 10+.
template {
  display: none;
}

// Add the correct display in IE 10.
[hidden] {
  display: none;
}

/* Table
========================================================================== */
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption {
  text-align: left;
}
td,
th {
  vertical-align: top;
}
th {
  text-align: left;
}

/* Interactive
   ========================================================================== */
/*
* Add the correct display in Edge, IE 10+, and Firefox.
*/
details {
  display: block;
}
/*
* Add the correct display in all browsers.
*/
summary {
  display: list-item;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
