@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap");

@import "./styles/invoker-base/index.scss";
@import "/nprogress.css";
@import "/datatable.css";
// @import "~js-plugin-circliful/dist/main.css";

:root {
  --viewport-height: 100vh;
  --swiper-pagination-color: #ff4040;
  --tenka-drawer: 999999999;
  --tenka-mobile-menu: 9999999999;
  --tenka-shadow-card: 0px 2px 4px #{$dark-alpha-10},
    0px 4px 6px #{$dark-alpha-20};
  // 0px 2px 4px #{$dark-alpha-10};
}

.romanji {
  letter-spacing: normal;
}

.desktop {
  @include screen(smallPhone) {
    display: none;
  }
  @include screen(phone) {
    display: none;
  }
  @include screen(tablet) {
    display: none;
  }
  @include screen(tabletLandscape) {
    display: initial;
  }
  @include screen(desktop) {
    display: initial;
  }
  @include screen(desktopWide) {
    display: initial;
  }
}

// Tables Styles
table {
  width: 100%;
  min-width: 924px;
  position: relative;
  box-shadow: 0px 4px 12px -10px $dark-hex-30;
  background-color: $light-alpha-100;
  border-radius: 8px;
}

table.scout {
  min-width: unset;
  box-shadow: none;
  border-radius: 0;
}

tr > th {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-top: 12px;
  font-weight: $semibold;
  font-size: 14px;
  position: sticky;
  top: 162px;
  background-color: $bg-primary;
  color: $dark-hex-80;
  // border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 12px -8px rgba(0, 0, 0, 0.2),
    inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;

  &:first-child {
    border-top-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
  }
}

tr > td {
  width: 100%;
  vertical-align: middle;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  font-size: 15px;
  line-height: 20px;
  transition: all 0.4s ease-in-out;
}

tr.scout > td {
  padding: 0;
}

tr:hover {
  // box-shadow: 0px 8px 20px -4px $dark-hex-30;
  background-color: $dark-alpha-10;
  transition: all 0.28s ease-in-out;

  // td:first-child {
  //   box-shadow: inset 4px 0px 0px $brand-primary-300;
  // }
}

tr.scout:hover {
  background-color: $dark-alpha-10;

  // td:first-child {
  //   box-shadow: none;
  // }
}

tr:last-child > td {
  // &:first-child {
  //   border-bottom-left-radius: 8px;
  // }

  // &:last-child {
  //   border-bottom-right-radius: 8px;
  // }
  border-bottom: none;
}

// RC-SLIDER

$slider-rail-color: $bg-gray-alt;
$slider-track-color: $dark-hex-50;

$slider-handle-bg-color: $bg-primary;
$slider-handle-border-color: $dark-hex-50;
$slider-handle-active-border-color: $dark-hex-50;

$slider-handle-dragging-border: $dark-hex-50;
$slider-handle-dragging-shadow: $dark-hex-50;

.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: $slider-rail-color;
  height: 4px;
  border-radius: 6px;
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: $slider-track-color;
}
.rc-slider-handle {
  position: absolute;
  width: 30px;
  height: 30px;
  margin-top: -14px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  background-color: $slider-handle-bg-color;
  border-radius: $radius-rounded;
  border: 1px solid $slider-handle-border-color;
  // box-shadow: 0px 4px 4px -2px $dark-alpha-20;
  touch-action: pan-x;
  outline: none;

  &::after {
    color: $dark-hex-50;
    content: "|||";
    display: block;
    font-size: 10px;
    line-height: 12px;
    height: 12px;
    position: absolute;
    transform: translateX(-50%);
    left: 48%;
    top: 8px;
  }
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: $slider-handle-dragging-border;
  // box-shadow: 0 0 0 1px $slider-handle-dragging-shadow;
}
.rc-slider-handle:focus {
  outline: none;
}
.rc-slider-handle-click-focused:focus {
  // border-color: $slider-handle-dragging-shadow;
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: $slider-handle-dragging-border;
}
.rc-slider-handle:active {
  border-color: $slider-handle-dragging-border;
  // box-shadow: 0 0 1px $slider-handle-dragging-border;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid $slider-rail-color;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.rc-slider-dot-active {
  border-color: #96dbfa;
}
.rc-slider-dot-reverse {
  margin-right: -4px;
}
.rc-slider-disabled {
  background-color: $slider-rail-color;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}
.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

// $spacing: 1rem;
// $themeColor: #63b8ff;
// $backColor: #ddd;
// $textShadow: rgba(black, 0.35) 1px 1px 1px;

// .progress--circle {
//   position: relative;
//   display: inline-block;
//   margin: $spacing;
//   width: 120px;
//   height: 120px;
//   border-radius: 50%;
//   background-color: $backColor;
//   &:before {
//     content: "";
//     position: absolute;
//     top: 15px;
//     left: 15px;
//     width: 90px;
//     height: 90px;
//     border-radius: 50%;
//     background-color: white;
//   }
//   &:after {
//     content: "";
//     display: inline-block;
//     width: 100%;
//     height: 100%;
//     border-radius: 50%;
//     background-color: $themeColor;
//   }
// }

// .progress__number {
//   position: absolute;
//   top: 50%;
//   width: 100%;
//   line-height: 1;
//   margin-top: -0.75rem;
//   text-align: center;
//   font-size: 1.5rem;
//   color: #777;
// }

// /**
// * $step is set to 5 by default, meaning you can only use percentage classes in increments of five (e.g. 25, 30, 45, 50, and so on). This helps to reduce the size of the final CSS file. If you need a number that doesn't end in 0 or 5, you can change the text percentage while rounding the class up/down to the nearest 5.
// */
// $step: 1;
// $loops: round(100 / $step);
// $increment: 360 / $loops;
// $half: round($loops / 2);
// @for $i from 0 through $loops {
//   .progress--bar.progress--#{$i * $step}:after {
//     width: $i * $step * 1%;
//   }
//   .progress--circle.progress--#{$i * $step}:after {
//     @if $i < $half {
//       $nextDeg: 90deg + ($increment * $i);
//       background-image: linear-gradient(
//           90deg,
//           $backColor 50%,
//           transparent 50%,
//           transparent
//         ),
//         linear-gradient($nextDeg, $themeColor 50%, $backColor 50%, $backColor);
//     } @else {
//       $nextDeg: -90deg + ($increment * ($i - $half));
//       background-image: linear-gradient(
//           $nextDeg,
//           $themeColor 50%,
//           transparent 50%,
//           transparent
//         ),
//         linear-gradient(270deg, $themeColor 50%, $backColor 50%, $backColor);
//     }
//   }
// }

.wp-block-image.container-width {
  height: auto;

  @include screen(smallPhone) {
    margin-left: -24px;
    margin-right: -24px;
    width: calc(100% + 48px);
  }
  @include screen(phone) {
    margin-left: -24px;
    margin-right: -24px;
    width: calc(100% + 48px);
  }
  @include screen(tablet) {
    margin-left: -24px;
    margin-right: -24px;
    width: calc(100% + 48px);
  }
  @include screen(tabletLandscape) {
    margin-left: -96px;
    margin-right: -96px;
    width: calc(100% + 192px);
  }
  @include screen(desktop) {
    margin-left: -96px;
    margin-right: -96px;
    width: calc(100% + 192px);
  }
  @include screen(desktopWide) {
    margin-left: -96px;
    margin-right: -96px;
    width: calc(100% + 192px);
  }
}

.has-text-align-center {
  text-align: center;
}
